.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.forminput label {
  width: 100%;
}

.forminput .input-group {
  width: 100%;
}

.forminput .react-datepicker-wrapper {
  display: grid;
  width: 100%;
}

/* file-upload */
.dropzone {
  position: relative;
  text-align: center;
}

.dz-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.dropzone .dz-message:focus {
  outline: 1px solid transparent !important;
}

.badgtab {
  width: 25% !important;
  text-align: left !important;
  border-radius: 5px !important;
}

/* stachbarchart -> chartist */
.stackbarchart .ct-bar {
  stroke-width: 30px;
}

.piechart .ct-chart .ct-label {
  color: white;
  fill: white;
  font-size: 16px;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.toolbarClassName {
  background: #edeff1;
  border-bottom: 1px solid #e9ecef;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rdw-option-wrapper {
  background-color: transparent;
}

.rdw-editor-main {
  padding: 10px;
}

/* Dropdown */
.splitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.splitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.dropsplitbtn .dropdown-toggle {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.smsplitbtn .dropdown-toggle {
  padding-right: 0.372rem;
  padding-left: 0.372rem;
}
.smsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.ltsplitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.ltsplitbtn .dropdown-toggle::before {
  margin-right: 0 !important;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .sweet-alert {
    width: 95% !important;
    right: 0;
    left: 8px !important;
    margin: 0!important;  
  }
}

.sweet-alert {
  margin-top: -161px !important;
}

.sweet-alert h2 {
  font-size: 24px !important;
}

/* form-editors */
.wrapperClassName {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.editorClassName,
.ql-editor {
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ql-toolbar.ql-snow {
  background: #edeff1;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.DraftEditor-root {
  height: auto;
}

/* file Xeditable */
.editable-container a {
  border-bottom: dashed 1px #0088cc;
}

/* form-mask */
/* .form-mask .form-control{
  height: calc(1.4em + 0.75rem + 1px);
} */

/* extra-pages (coming soon) */
.coming-box {
  border: none !important;
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44) !important;
  margin-bottom: 30px !important;
  margin: 0 15px;
  padding: 16px 16px 0 16px;
  background-color: white;
}

.coming-watch div {
  font-size: 40px !important;
  color: #626ed4 !important;
  font-weight: 500;
}

.coming-watch div span:first-child {
  height: 50px !important;
}

.coming-watch div span:last-child {
  font-size: 12px !important;
  color: #5b626b;
  text-transform: uppercase;
}

/* email-template */
.email-template .content {
  max-width: 570px !important;
}

/* create btn */
.btn-light {
  color: #212529 !important;
}

/* dashboard */
.dash-chart .chat-avatar {
  margin: 0 8px;
}

/* rating */
.rating-container {
  min-width: auto !important;
}

/* switch */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .react-switch-bg {
    margin-bottom: 8px !important;
  }
}

/* Form wizard */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .form-wizard .badgtab {
    width: 100% !important;
  }
  .form-wizard .col-form-label {
    text-align: left;
  }
}

/* 2-sept */
/* @media screen and (max-width: 426px) and (min-width: 320px) {
  svg {
    width: 100% !important;
  }
  .apexcharts-canvas {
    width: 205px !important;
  }
} */

.coming-watch div span:last-child {
  padding-top: 0px !important;
}

.react-datepicker-popper {
  z-index: 99;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .coming-soon-search-form {
    margin-bottom: 24px;
  }
  .conversation-list .odd{
    display: flex;
    flex-direction: row-reverse;
  }
}

/* 5-september */
.client-reviews {
  padding-bottom: 18px;
}

.data-range1 input{
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.data-range2 input{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ti-archive{
  margin-right: 12px !important;
}


.rangeslider-horizontal .rangeslider__fill
{
  background-color: #626ed4!important;
}
.rangeslider rangeslider-horizontal
{
  height: 8px;
}
.rangeslider__fill
{
  width: 37px;
}

.rangeslider-horizontal .rangeslider__fill
{
  height: 73%;
}
#right_badge
{
  float: right;
}
.rangeslider-horizontal {
  height: 8px;
}

